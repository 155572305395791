/* Bootstrap */
@import '~bootstrap/dist/css/bootstrap.css';
/* custom typography theme */
@import './typography.css';

:root {
  --quote-gap: 20px;
  --color-primary: #5bbccc;
  --color-secondary: #ec672b;
  --font-family: 'Work Sans', Arial, sans-serif;
  --layout-padding-top: 2rem;
}

body {
  margin: 0;
  background-color: var(--color-primary);
  color: black;
  font-family: var(--font-family);
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding-top: 4.5rem;
  overflow: hidden;
}

img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.2;
}

p {
  line-height: 1.2;
}

.text-secondary,
a.text-secondary:hover,
a.text-secondary:focus {
  color: var(--color-secondary) !important;
}

input,
textarea {
  border: 1px solid var(--color-primary);
}

.btn {
  font-weight: 800;
  text-transform: uppercase;
}
.btn-secondary {
  border: none;
  border-radius: 0;
  padding: 1rem 2rem;
  box-shadow: none !important; /* overriding :focus & :active */
  background-color: var(--color-secondary) !important; /* overriding bootstrap color */
  -webkit-mask-image: url('images/svg/button-secondary.svg');
  mask-image: url('images/svg/button-secondary.svg');
  -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
  outline: none;
}

.btn-primary {
  border: none;
  border-radius: 0;
  padding: 1rem 2rem;
  box-shadow: none !important; /* overriding :focus & :active */
  background-color: var(--color-primary) !important; /* overriding bootstrap color */
  -webkit-mask-image: url('images/svg/button-secondary.svg');
  mask-image: url('images/svg/button-secondary.svg');
  -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
  outline: none;
}

.with-bar {
  margin-left: -0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0.1rem;
  background-image: url('images/svg/bar.svg');
  background-repeat: no-repeat;
  background-size: 5rem 1rem;
  background-position: -0.5rem bottom;
}
@media (min-width: 1200px) {
  /* .with-bar-xl is EXPERIMENTAL */
  .with-bar.with-bar-xl {
    background-size: 8rem 1.5rem;
  }
}

.terms-bar {
  padding-bottom: 0;
  background-size: 5rem 1.3rem;
}

/* utility */
.font-weight-600 {
  font-weight: 600;
}

.quote-highlight {
  padding-bottom: 0.1rem;
  background-image: url('images/svg/bar.svg');
  background-repeat: no-repeat;
}

.right-quote-arrow,
.left-quote-arrow {
  position: absolute;
  width: 27rem;
  height: 1.23rem;
  margin-top: 1.5rem;
}

.right-quote-arrow {
  left: 50%;
}

.left-quote-arrow {
  right: 50%;
}

.ot-floating-button__open,
.ot-floating-button__close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.paypal-buttons {
  z-index: 1;
}

.base-link {
  color: var(--color-secondary) !important; /* link */
  font-weight: 800;
  text-decoration: underline;
  word-break: break-all;
}

.yt-lite:before {
  content: none !important;
}

@media (min-width: 576px) {
  .right-quote-arrow {
    margin-top: 0.2rem;
    left: 75%;
  }
  .left-quote-arrow {
    margin-top: 0.2rem;
    right: 60%;
  }
}

@media (min-width: 768px) {
  .right-quote-arrow {
    left: 90%;
  }
  .left-quote-arrow {
    right: 72%;
  }
}

@media (min-width: 992px) {
  .right-quote-arrow {
    left: 96%;
  }
  .left-quote-arrow {
    right: 85%;
  }
}

@media (min-width: 1200px) {
  .right-quote-arrow {
    left: 90%;
  }
  .left-quote-arrow {
    right: 90%;
  }
}

@media (min-width: 1500px) {
  .right-quote-arrow {
    left: 97%;
  }

  .left-quote-arrow {
    right: 97%;
  }
}

#onetrust-accept-btn-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

#onetrust-pc-btn-handler {
  color: grey !important;
  border-color: lightgray !important;
}

#onetrust-banner-sdk .banner_logo {
  display: inline-block !important;
  background-image: url('./logo.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 15px;
  margin-top: 10px !important;
  margin-left: 30px !important;
}

@media (max-width: 425px) {
  #onetrust-banner-sdk .banner_logo {
    display: block !important;
    width: 100px;
    margin: 10px auto 0 auto !important;
  }
  #onetrust-policy {
    margin-top: 15px !important;
  }

  #onetrust-button-group {
    display: flex !important;
    flex-direction: column-reverse;
    margin-bottom: 25px !important;
  }
}

#onetrust-policy-text a {
  color: grey !important;
}

.ot-pc-refuse-all-handler {
  background-color: white !important;
  color: grey !important;
  border-color: lightgray !important;
}

.ot-pc-logo {
  background-image: url('./logo.png') !important;
}


.myThumbsSwiper {
  margin-top: 30px;
}

.myThumbsSwiper .swiper-slide {
  opacity: 0.4;
}

.gallerySwiper .swiper-slide {
  max-height: 530px;
  aspect-ratio: 1/1;
}

.gallerySwiper .swiper-slide img{
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  object-fit: cover;
}

.myThumbsSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.gallerySwiper.swiper-container {
  position: relative;
}

.gallerySwiper .swiper-button-next {
  right: 5px;
  top: 50%;
  background-color: #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: 6px;
}

.gallerySwiper .swiper-button-prev {
  left: 5px;
  top: 50%;
  background-color: #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: 6px;
}

.gallerySwiper .swiper-wrapper {
  align-items: center;
}
