.trustpilotContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 45px;
    font-weight: 500;
    font-size: 28px;
    gap: 10px;
    line-height: 1.05;
}

@media screen and (max-width: 768px){
    .trustpilotContainer {
        flex-direction: column-reverse;
    }
}
