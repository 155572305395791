.paragraph {
  margin-bottom: 2rem;
  color: white;
  line-height: 1.2;
}

.testimonial {
  max-width: 22rem;
  margin: 0 auto;
  opacity: 0;
  /*transform: translateX(1000px);*/
}

.animation {
  animation: fade-in forwards 1s 2s ease-in-out;
}

.title {
  opacity: 0;
}

.titleAnimation {
  animation: fade-in 1s forwards ease-in-out;
}

.feedbackContainer {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.feedback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background-image: url('../../../images/svg/sheet.svg');
  background-size: 100% 100%;
}

.feedbackText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.feedbackName {
  text-align: right;
  font-weight: 800;
  text-transform: uppercase;
}

.feedbackFirstName {
  display: inline-block;
  margin-right: -0.25rem;
  padding: 0.25rem;
  background-image: url('../../../images/svg/bar-secondary.svg');
  background-size: 100% 100%;
  color: white;
  font-size: 1.25rem;
}

.feedbackLastName {
  margin-top: -0.5rem;
}

.textSecondary {
  color: var(--color-secondary);
}

.navigationButton {
  top: 45% !important;
}

.testimonialsContainer {
  position: relative;
}

/* keyframes */
@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes move-in {
  100% {
    transform: translateX(0);
  }
}
